<div *ngIf="globals.Store.Customer.Cart" [ngClass]="{ hidden: this.isRedirecting, checkout__main: true }">
  <div class="row m-0 p-2">
    <div class="col-md-8">
      <mat-card>
        <h5 class="title-text">Choose Delivery Option</h5>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-select
                name="DeliveryCourier"
                placeholder="Courier"
                [(ngModel)]="globals.Store.Customer.Cart.Delivery.CourierId"
                (selectionChange)="deliveryCourierChanged($event)"
                required
              >
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let option of Delivery.Couriers" [value]="option.id.toString()">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-4" *ngIf="globals.Store.Customer.Cart.DeliveryMethod != 1">
            <mat-form-field class="w-100">
              <mat-select
                name="DeliveryState"
                placeholder="Province"
                [(ngModel)]="globals.Store.Customer.Cart.Delivery.StateId"
                (selectionChange)="deliveryStateChanged($event)"
                required
              >
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let option of Delivery.States" [value]="option.id.toString()">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-4" *ngIf="globals.Store.Customer.Cart.DeliveryMethod != 1">
            <mat-form-field class="w-100">
              <mat-select
                name="DeliveryCity"
                placeholder="Area"
                [(ngModel)]="globals.Store.Customer.Cart.Delivery.CityId"
                (selectionChange)="deliveryCityChanged($event)"
                required
              >
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let option of Delivery.Cities" [value]="option.id.toString()">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <mat-card *ngIf="globals.Store.Customer.Cart.Billing">
        <div *ngIf="!globals.Store.Customer.Cart.Delivery.CourierId" class="alert alert-warning">Please select a delivery method above</div>
        <h5 class="title-text">Billing address</h5>
        <mat-checkbox
          *ngIf="globals.Store.Customer.Cart.DeliveryMethod > 0"
          [(ngModel)]="globals.Store.Customer.Cart.UseSameAddress"
          (change)="useSameAddressChanged($event)"
        >
          Ship to the same address
        </mat-checkbox>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="First Name" [(ngModel)]="globals.Store.Customer.Cart.Billing.FirstName" (keyup)="onChange()" required />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Last Name" [(ngModel)]="globals.Store.Customer.Cart.Billing.LastName" (keyup)="onChange()" required />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Mobile Number</mat-label>
              <span matPrefix class="text-bold">+639</span>
              <input matInput placeholder="991234567" [minlength]="9" [maxlength]="9" [(ngModel)]="globals.Store.Customer.Cart.Billing.PhoneNumber" (keyup)="onChange()" (keypress)="numberOnly($event)" (paste)="onPaste($event)" required />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <input type="email" matInput placeholder="Email Address" [(ngModel)]="globals.Store.Customer.Cart.Billing.Email" (keyup)="onChange()" required />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="globals.Store.Customer.Cart.DeliveryMethod > 0">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-select
                name="BillingCountry"
                placeholder="Country"
                autocomplete="off"
                [disabled]="!globals.Store.Customer.Cart.Delivery.CourierId"
                [(ngModel)]="globals.Store.Customer.Cart.Billing.CountryCode"
                (selectionChange)="billingCountryChanged($event)"
                required
              >
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let option of Billing.Countries" [value]="option.id.toString()">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- <div class="col">
						<mat-form-field class="w-100">
							<mat-select name="BillingCourier" placeholder="Courier"
								[(ngModel)]="globals.Store.Customer.Cart.Billing.CourierId"
								(selectionChange)="billingCourierChanged($event)" required>
								<mat-option value="">---</mat-option>
								<mat-option *ngFor="let option of Billing.Couriers" [value]="option.id.toString()">
									{{option.name}}
								</mat-option>
							</mat-select>
							<mat-error>{{getErrorMessage()}}</mat-error>
						</mat-form-field>
					</div> -->

          <div class="col">
            <mat-form-field class="w-100">
              <mat-select
                name="BillingState"
                placeholder="Province"
                [(ngModel)]="globals.Store.Customer.Cart.Billing.StateId"
                [disabled]="!globals.Store.Customer.Cart.Delivery.CourierId"
                (selectionChange)="billingStateChanged($event)"
                required
              >
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let option of Billing.States" [value]="option.id.toString()">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field class="w-100">
              <mat-select
                name="BillingCity"
                placeholder="City / Municipality"
                [(ngModel)]="globals.Store.Customer.Cart.Billing.CityId"
                [disabled]="!globals.Store.Customer.Cart.Delivery.CourierId"
                (selectionChange)="billingCityChanged($event)"
                required
              >
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let option of Billing.Cities" [value]="option.id.toString()">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <input
                matInput
                [placeholder]="globals.Store.Customer.Cart.DeliveryMethod == 0 ? 'Address 1' : 'House No. / Street / Subdivision'"
                [(ngModel)]="globals.Store.Customer.Cart.Billing.Address1"
                (keyup)="onChange()"
                required
              />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <input
                matInput
                [placeholder]="globals.Store.Customer.Cart.DeliveryMethod == 0 ? 'Address 2' : 'Town / Barangay'"
                [(ngModel)]="globals.Store.Customer.Cart.Billing.Address2"
                (keyup)="onChange()"
                required
              />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Remarks / Landmark / Instruction" (keyup)="onChange()" [(ngModel)]="globals.Store.Customer.Cart.Billing.Notes" />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>

      <mat-card *ngIf="globals.Store.Customer.Cart.DeliveryMethod > 0 && !globals.Store.Customer.Cart.UseSameAddress">
        <h5 class="title-text">Shipping address</h5>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="First Name" [(ngModel)]="globals.Store.Customer.Cart.Shipping.FirstName" required />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Last Name" [(ngModel)]="globals.Store.Customer.Cart.Shipping.LastName" required />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Mobile Number</mat-label>
              <span matPrefix class="text-bold">+639</span>
              <input matInput placeholder="991234567" [minlength]="9" [maxlength]="9" [(ngModel)]="globals.Store.Customer.Cart.Shipping.PhoneNumber" (keypress)="numberOnly($event)" (paste)="onPaste($event)" required />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <input type="email" matInput placeholder="Email Address" [(ngModel)]="globals.Store.Customer.Cart.Shipping.Email" required />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-select
                name="ShippingCountry"
                placeholder="Country"
                [(ngModel)]="globals.Store.Customer.Cart.Shipping.CountryCode"
                (selectionChange)="shippingCountryChanged($event)"
                required
              >
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let option of Shipping.Countries" [value]="option.id.toString()">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <mat-select
                name="ShippingCourier"
                placeholder="Courier"
                [(ngModel)]="globals.Store.Customer.Cart.Shipping.CourierId"
                (selectionChange)="shippingCourierChanged($event)"
                required
              >
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let option of Shipping.Couriers" [value]="option.id.toString()">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-select
                name="ShippingState"
                placeholder="Province"
                [(ngModel)]="globals.Store.Customer.Cart.Shipping.StateId"
                (selectionChange)="shippingStateChanged($event)"
                required
              >
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let option of Shipping.States" [value]="option.id.toString()">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <mat-select
                name="ShippingCity"
                placeholder="City / Municipality"
                [(ngModel)]="globals.Store.Customer.Cart.Shipping.CityId"
                (selectionChange)="shippingCityChanged($event)"
                required
              >
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let option of Shipping.Cities" [value]="option.id.toString()">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="House No. / Street / Subdivision" [(ngModel)]="globals.Store.Customer.Cart.Shipping.Address1" required />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Town / Barangay" [(ngModel)]="globals.Store.Customer.Cart.Shipping.Address2" required />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Remarks / Landmark / Instruction" [(ngModel)]="globals.Store.Customer.Cart.Shipping.Notes" />
              <mat-error>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>

      <mat-card>
        <h5 class="title-text">Order Details</h5>
        <table class="table table-details">
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col" class="text-right">Quantity</th>
              <th scope="col" class="text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of globals.Store.Customer.Cart.Items">
              <td scope="row">{{ item.Product.Name }}</td>
              <td class="text-right">
                {{ item.Quantity }}
                <span style="display: none">{{ item.Quantity * item.Product.Weight | number: '1.2-2' }}</span>
              </td>
              <td class="text-right">{{ globals.Store.Currency }} {{ item.Quantity * item.Product.Price | number: '1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </div>
    <div class="col-md-4">
      <app-order-summary></app-order-summary>
      <mat-card>
        <div class="example-container">
          <h5 class="title-text">Choose Payment Option</h5>
          <small class="mb-3" *ngIf="!IsPageValid(false)">*Choices will be enabled when billing form is complete and valid</small>
          <mat-radio-group
            class="example-radio-group"
            [(ngModel)]="globals.Store.Customer.Cart.PaymentMethod"
            [disabled]="!IsPageValid(false)"
            (change)="onPaymentMethodChanged($event)"
          >
            <mat-radio-button class="example-radio-button" [value]="3"> Online Payment </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="1"> Debit / Credit </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="2" *ngIf="globals.Store.Customer.Cart.DeliveryMethod == 1">
              Cash on Delivery
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="5" *ngIf="globals.Store.Customer.Cart.DeliveryMethod == 0">
              Cash on Pickup
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="4"> Paypal </mat-radio-button>
            <!-- <mat-radio-button class="example-radio-button" [value]="6" *ngIf="globals.Store.Customer.Cart.DeliveryMethod == 1">
              EBank
            </mat-radio-button> -->
          </mat-radio-group>
        </div>
        <div class="text-center">
          <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
        </div>
        <div class="p-4 text-center">
          <div *ngIf="globals.Store.Customer.Cart.PaymentMethod != 4 && IsPageValid(false)">
            <button *ngIf="!this.isLoading" class="mx-2 w-100" mat-raised-button color="warn" (click)="confirm()">PAY NOW</button>
            <div *ngIf="this.isLoading" style="display: inline-block; width: 30px; height: 30px; margin-left: 30px">
              <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
            </div>
            <span *ngIf="this.isLoading" style="display: inline-block; position: relative; top: -10px; left: 10px">Processing...</span>
          </div>
          <div>
            <ng-template appPaypal></ng-template>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <br />
  <div class="row m-0">
    <div class="col-md-8"></div>
  </div>
</div>

<div class="loader__container" *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<form #frmGateway action="{{ this.payURL_paynamics }}" id="frmGateway" method="post" target="_blank">
  <input #paymentrequest type="hidden" name="paymentrequest" id="paymentrequest" value="" />
</form>
