import { Courier } from './../_models/courier';
import { Country } from './../_models/country';
import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CartService } from '../_services/cart.service';
import { RegionalService } from '../_services/regional.service';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';
import { PaypalDirective } from '../paypal.directive';
import { ICreateOrderRequest, IPayPalConfig, NgxPaypalComponent, PayPalScriptService } from 'ngx-paypal';
import { CartItem } from '../_models/cart-item';
import { IInitCallbackData, IOnInitCallbackActions } from 'ngx-paypal/lib/models/paypal-models';

declare let fbq: Function;

const CREDIT_CARD_CHARGE = 'CREDITCARD';
const PAYPAL_CHARGE = 'PAYPAL';

const PAYPAL_PAYMENT_METHOD = 4;
const CREDIT_CARD_PAYMENT_METHOD = 1;

const ADDITIONAL_PAYPAL_CHARGE = 15;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, AfterViewInit {
  onPayment: Function;
  onAuthorize: Function;
  @ViewChild(PaypalDirective, { static: false }) paypalDirective: PaypalDirective;
  @ViewChild('frmGateway') formGateway;
  @ViewChild('paymentrequest') paymentrequest;
  @ViewChild('appPaypal') paypalButton?: NgxPaypalComponent;

  isLoading = false;
  isRedirecting = false;
  responseContent = '';
  isForShipping = true;
  selectedState = '6';
  selectedCity = '';

  Delivery = {
    Countries: [],
    Couriers: [],
    CourierMap: {},
    States: [],
    Cities: [],
  };

  Billing: any = {
    Countries: [],
    Couriers: [],
    States: [],
    Cities: [],
  };

  Shipping: any = {
    Countries: [],
    Couriers: [],
    States: [],
    Cities: [],
  };

  payURL_paynamics: any;
  payURL_ub: any;
  ub_uuid: any;
  ub_aes_key: any;
  ub_ecrypted_payload: any;
  resultOrderNumber: number;
  viewContainerRef: ViewContainerRef;
  paypalComponentFactory: ComponentFactory<NgxPaypalComponent>;
  paypalComponentRef: ComponentRef<NgxPaypalComponent>;
  paymentMethod: number;
  captchaResponse: string;

  siteKey = '6LfF-7IaAAAAAKeCS3rkKrnzvv91n-J_jzCdF6Io';

  constructor(
    private router: Router,
    public globals: Globals,
    private location: Location,
    private snackBar: MatSnackBar,
    public cartService: CartService,
    public regionalService: RegionalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private payPalScriptService: PayPalScriptService,
  ) {
    this.globals.Store.Customer.Cart.Delivery = { CourierId: 0, StateId: 0 };
    this.onPayment = (data: any, actions: { (arg0: Error): void; (arg0: Error): void; payment: any; }) => {
      console.log('Data: ', data);
      // console.log('Actions: ', actions(new Error('TEST ERROR')));
      if (!this.IsPageValid(true)) {
        actions(new Error('TEST ERROR'));
      }
      this.globals.Store.Customer.Cart.PaymentMethod = 4;
      this.confirm();
      const totalPayment = this.globals.getGrandTotal();
      return actions.payment.create({
        payment: {
          transactions: [
            {
              amount: {
                total: totalPayment,
                currency: this.globals.Store.Currency,
              },
            },
          ],
        },
      });
    };

    this.onAuthorize = (data: any, actions: any) => {
      this.removePaypalButton();
      console.log(data);
      console.log(actions);
      this.globals.goToUrl(this.router, `completed/${this.resultOrderNumber}`);
    };
  }

  ngAfterViewInit(): void {
    this.viewContainerRef = this.paypalDirective.viewContainerRef;
    this.paypalComponentFactory = this.componentFactoryResolver.resolveComponentFactory(NgxPaypalComponent);
    console.log(this.paypalDirective);
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captchaResponse = captchaResponse;
    if(!captchaResponse) {
      this.removePaypalButton();
    } else {
      if(this.globals.Store.Customer.Cart.PaymentMethod == PAYPAL_PAYMENT_METHOD) {
        this.loadPaypalButton();
      }
    }
  }

  removePaypalButton() {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }

    if (this.paypalComponentRef) {
      this.paypalComponentRef.destroy();
    }
    this.payPalScriptService.destroyPayPalScript();
  }

  loadPaypalButton() {
    this.paypalComponentRef = this.viewContainerRef.createComponent<NgxPaypalComponent>(this.paypalComponentFactory);
    this.paypalComponentRef.instance.config = this.getPaypalConfig();

    this.payPalScriptService.registerPayPalScript(
      {
        clientId: environment.paypalClientId,
        currency: this.globals.Store.Currency,
      },
      (payPalApi) => {
        if (this.paypalComponentRef.instance) {
          this.paypalComponentRef.instance.customInit(payPalApi);
        }
      },
    );
  }

  onPaymentMethodChanged(event: any) {
    this.paymentMethod = this.globals.Store.Customer.Cart.PaymentMethod;
    console.log('payment method: ' + this.paymentMethod);
    this.removePaypalButton();
    if (this.paymentMethod == CREDIT_CARD_PAYMENT_METHOD) {
      this.globals.Store.Customer.Cart.OtherCharges = [];
      this.globals.Store.Customer.Cart.OtherCharges.push({
        Type: CREDIT_CARD_CHARGE,
        Amount: this.computeCreditCardCharge(),
      });
      return;
    }

    if (this.paymentMethod == PAYPAL_PAYMENT_METHOD) {
      this.globals.Store.Customer.Cart.OtherCharges = [];
      this.globals.Store.Customer.Cart.OtherCharges.push({
        Type: PAYPAL_CHARGE,
        Amount: this.computePaypalCharge(),
      });
      this.loadPaypalButton();
      return;
    }

    this.globals.Store.Customer.Cart.OtherCharges = [];
  }

  computeCreditCardCharge() {
    //return this.computeTotalAmountWithShippingFee() * environment.creditCardChargePercent;
    return (this.computeTotalAmountWithShippingFee() * 0.04) + 20;
  }

  computePaypalCharge() {
    return this.computeTotalAmountWithShippingFee() * environment.paypalChargePercent + ADDITIONAL_PAYPAL_CHARGE;
  }

  computeTotalAmountWithShippingFee() {
    return this.globals.getCartTotalAmount() + this.globals.Store.Customer.Cart.ShippingFee;
  }

  ngOnInit() {
    if (this.globals.isCartEmpty()) {
      this.globals.goToUrl(this.router, 'cart');
    }
    this.regionalService
      .getCourierByCountryCode(this.globals.Store.VendorCountryCode)
      .subscribe((couriers: Courier[]) => {
        this.Delivery.Couriers = couriers;
        couriers.forEach((courier: Courier) => {
          this.Delivery.CourierMap[courier.id] = courier;
        });
        console.log('Courier Map: ', this.Delivery.CourierMap);
      });
    this.regionalService.getCountryByCode(this.globals.Store.VendorCountryCode).subscribe({
      next: (country) => {
        this.Billing.Countries = [country];
        this.Shipping.Countries = [country];
      },
      error: (err) => {
        console.error(err);
      },
      complete: null,
    });
    // this.regionalService.getCountries().subscribe((countries) => {
    //   this.Billing.Countries = countries;
    //   this.Shipping.Countries = countries;
    // });

    this.payURL_paynamics = environment.payUrl_paynamics;
    this.payURL_ub = environment.payUrl_ub;
    this.ub_uuid = environment.ub_uuid;
    this.ub_aes_key = environment.ub_aes_key;

    if (this.globals.Store.Customer.Cart.PaymentMethod === 4) {
      this.loadPaypalButton();
    } else {
      this.removePaypalButton();
    }
  }

  goBack(): void {
    this.location.back();
  }

  onDeliveryMethodChanged(): void {
    this.globals.Store.Customer.Cart.ShippingFee = 0;
    if (this.globals.Store.Customer.Cart.DeliveryMethod === 1) {
      // Shipping
      this.Billing.Cities = null;
      this.Shipping.Cities = null;
      this.globals.Store.Customer.Cart.Billing.StateId = null;
      this.globals.Store.Customer.Cart.Billing.CityId = null;
      this.globals.Store.Customer.Cart.Billing.State = null;
      this.globals.Store.Customer.Cart.Billing.City = null;
      this.globals.Store.Customer.Cart.Shipping.StateId = null;
      this.globals.Store.Customer.Cart.Shipping.CityId = null;
      this.globals.Store.Customer.Cart.Shipping.State = null;
      this.globals.Store.Customer.Cart.Shipping.City = null;
    } else if (this.globals.Store.Customer.Cart.DeliveryMethod === 2) {
      // Pickup
      if (this.globals.Store.Customer.Cart.PaymentMethod === 2) {
        this.globals.Store.Customer.Cart.PaymentMethod = 1;
      }
    }
  }

  useSameAddressChanged(event: any): void {
    this.globals.Store.Customer.Cart.ShippingFee = 0.0;
    this.Billing.Cities = null;
    this.Shipping.Cities = null;
    this.globals.Store.Customer.Cart.Billing.StateId = null;
    this.globals.Store.Customer.Cart.Billing.CityId = null;
    this.globals.Store.Customer.Cart.Billing.State = null;
    this.globals.Store.Customer.Cart.Billing.City = null;
    this.globals.Store.Customer.Cart.Shipping.StateId = null;
    this.globals.Store.Customer.Cart.Shipping.CityId = null;
    this.globals.Store.Customer.Cart.Shipping.State = null;
    this.globals.Store.Customer.Cart.Shipping.City = null;
  }

  billingCountryChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('billingCountryChanged: ' + selectedData.text);
    this.globals.Store.Customer.Cart.Billing.Country = selectedData.text;
    if (selectedData.value && selectedData.value !== '') {
      this.regionalService.getStatesByCourier(this.globals.Store.Customer.Cart.Delivery.CourierId.toString()).subscribe(
        (states) => {
          this.Billing.States = states;
        },
        (err) => {
          console.error(err);
          this.Billing.States = [];
        },
      );
    } else {
      this.Billing.States = [];
      if (this.globals.Store.Customer.Cart.UseSameAddress) {
        this.globals.Store.Customer.Cart.ShippingFee = 0.0;
      }
    }
  }

  billingCourierChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('billingCourierChanged: ' + selectedData.text);
    this.globals.Store.Customer.Cart.Billing.Courier = selectedData.text;
    if (selectedData.value && selectedData.value !== '') {
      this.regionalService.getStatesByCourier(selectedData.value).subscribe(
        (states) => {
          this.Billing.States = states;
        },
        (err) => {
          console.error(err);
          this.Billing.States = [];
        },
      );
    } else {
      this.Billing.States = [];
      if (this.globals.Store.Customer.Cart.UseSameAddress) {
        this.globals.Store.Customer.Cart.ShippingFee = 0.0;
      }
    }
  }

  deliveryCourierChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('deliveryCourierChanged: ' + selectedData.text);
    console.log('deliveryCourierChanged: ' + selectedData.value);
    this.globals.Store.Customer.Cart.Delivery.CourierId = selectedData.value;
    this.globals.Store.Customer.Cart.Delivery.Courier = selectedData.text;
    if (selectedData.value && selectedData.value !== '') {
      this.globals.Store.Customer.Cart.DeliveryMethod =
        this.Delivery.CourierMap[Number(event.value)].courierType == 'PICKUP' ? 0 : 1;
      this.globals.Store.Customer.Cart.UseSameAddress = true;
      this.regionalService.getStatesByCourier(selectedData.value).subscribe(
        (states) => {
          this.Delivery.States = states;
        },
        (err) => {
          console.error(err);
          this.Delivery.States = [];
        },
      );
    } else {
      this.Delivery.States = [];
    }
  }

  deliveryStateChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('deliveryStateChanged: ' + selectedData.text);
    console.log('deliveryStateChanged: ' + selectedData.value);
    this.globals.Store.Customer.Cart.Delivery.StateId = selectedData.value;
    this.globals.Store.Customer.Cart.Delivery.State = selectedData.text;

    if (selectedData.value && selectedData.value !== '') {
      this.regionalService.getCitiesByState(selectedData.value).subscribe(
        (cities) => {
          this.Delivery.Cities = cities;
        },
        (err) => {
          console.error(err);
          this.Delivery.Cities = [];
        },
      );
    } else {
      this.Delivery.Cities = [];
    }
  }

  deliveryCityChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('deliveryCityChanged: ' + selectedData.text);
    console.log('deliveryCityChanged: ' + selectedData.value);
    this.globals.Store.Customer.Cart.Delivery.CityId = selectedData.value;
    this.globals.Store.Customer.Cart.Delivery.City = selectedData.text;
    this.regionalService
      .pricesByWeightAndCity(this.globals.getTotalWeight(), selectedData.value)
      .subscribe((res: any) => {
        this.globals.Store.Customer.Cart.ShippingFee = res.amount;
      });
  }

  billingStateChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('billingStateChanged: ' + selectedData.text);
    this.globals.Store.Customer.Cart.Billing.State = selectedData.text;
    if (selectedData.value && selectedData.value !== '') {
      this.regionalService.getCitiesByState(selectedData.value).subscribe(
        (cities) => {
          this.Billing.Cities = cities;
        },
        (err) => {
          console.error(err);
          this.Billing.Cities = [];
        },
      );
    } else {
      this.Billing.Cities = [];
      if (this.globals.Store.Customer.Cart.UseSameAddress) {
        this.globals.Store.Customer.Cart.ShippingFee = 0.0;
      }
    }
  }

  billingCityChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('billingCityChanged: ' + selectedData.text);
    this.globals.Store.Customer.Cart.Billing.City = selectedData.text;
    if (this.globals.Store.Customer.Cart.DeliveryMethod === 1 && this.globals.Store.Customer.Cart.UseSameAddress) {
      if (selectedData.value && selectedData.value !== '') {
        this.regionalService
          .pricesByWeightAndCity(this.globals.getTotalWeight(), selectedData.value)
          .subscribe((res: any) => {
            this.globals.Store.Customer.Cart.ShippingFee = res.amount;
          });
      } else {
        this.globals.Store.Customer.Cart.ShippingFee = 0.0;
      }
    }
  }

  shippingCountryChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('shippingCountryChanged: ' + selectedData.text);
    this.globals.Store.Customer.Cart.Shipping.Country = selectedData.text;
    if (selectedData.value && selectedData.value !== '') {
      this.regionalService.getCourierByCountry(selectedData.value).subscribe(
        (couriers) => {
          this.Shipping.Couriers = couriers;
        },
        (err) => {
          console.error(err);
          this.Shipping.Couriers = [];
        },
      );
    } else {
      this.Shipping.Couriers = [];
      if (this.globals.Store.Customer.Cart.UseSameAddress) {
        this.globals.Store.Customer.Cart.ShippingFee = 0.0;
      }
    }
  }

  shippingCourierChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('shippingCourierChanged: ' + selectedData.text);
    this.globals.Store.Customer.Cart.Shipping.Courier = selectedData.text;
    if (selectedData.value && selectedData.value !== '') {
      this.regionalService.getStatesByCourier(selectedData.value).subscribe(
        (states) => {
          this.Shipping.States = states;
        },
        (err) => {
          console.error(err);
          this.Shipping.States = [];
        },
      );
    } else {
      this.Shipping.States = [];
      if (this.globals.Store.Customer.Cart.UseSameAddress) {
        this.globals.Store.Customer.Cart.ShippingFee = 0.0;
      }
    }
  }

  shippingStateChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('shippingStateChanged: ' + selectedData.text);
    this.globals.Store.Customer.Cart.Shipping.State = selectedData.text;
    if (selectedData.value && selectedData.value !== '') {
      this.regionalService.getCitiesByState(selectedData.value).subscribe(
        (cities) => {
          this.Shipping.Cities = cities;
        },
        (err) => {
          console.error(err);
          this.Shipping.Cities = [];
        },
      );
    } else {
      this.Shipping.Cities = [];
      if (!this.globals.Store.Customer.Cart.UseSameAddress) {
        this.globals.Store.Customer.Cart.ShippingFee = 0.0;
      }
    }
  }

  shippingCityChanged(event: { source: { selected: { _element: { nativeElement: any; }; }; }; value: any; }): void {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log('shippingCityChanged: ' + selectedData.text);
    this.globals.Store.Customer.Cart.Shipping.City = selectedData.text;
    if (this.globals.Store.Customer.Cart.DeliveryMethod === 1 && !this.globals.Store.Customer.Cart.UseSameAddress) {
      if (selectedData.value && selectedData.value !== '') {
        this.regionalService
          .pricesByWeightAndCity(this.globals.getTotalWeight(), selectedData.value)
          .subscribe((price) => {
            this.globals.Store.Customer.Cart.ShippingFee = price.amount;
          });
      } else {
        this.globals.Store.Customer.Cart.ShippingFee = 0.0;
      }
    }
  }

  confirm(): void {
    if (!this.IsPageValid(true)) {
      return;
    }
    console.log('Confirm checkout');
    this.isLoading = true;
    fbq('track', 'Purchase', {
      contents: this.globals.Store.Customer.Cart.Items.map((item, index, array) => {
        return {
          id: item.Product.Id,
          quantity: item.Quantity,
          name: item.Product.Name,
        };
      }),
      currency: this.globals.Store.Currency,
      value: this.globals.getGrandTotal(),
    });
    if (this.globals.Store.Customer.Cart.DeliveryMethod == 0) {
      this.globals.Store.Customer.Cart.PickupLocation == this.globals.Store.Customer.Cart.Delivery.StateId;
    }

    // this will be parsed by `this.ub_encrypt`
    var complete_order_details = JSON.stringify(this.globals.Store.Customer.Cart);

    // fix the mobile number in the original object: add '09'

    var data_object = this.globals.Store.Customer.Cart;

    console.log('Billing Phone Number');

    Object.keys(data_object).forEach((key, index) => {
      // if(typeof data_object[item] == "number" && data_object[item] >= 4) {
      //   data_object[item] = 10
      // }
      console.log(data_object[key]);
      // console.log('AAA: '  + this.Billing.PhoneNumber);
    })

    console.log('end -------');
    this.cartService.checkout(this.globals.Store.Customer.Cart).subscribe(
      async (res) => {

        // checkout response does not contain item details 

        this.resultOrderNumber = res; // order number only
        this.isLoading = false;
        this.isRedirecting = true;

        if (this.globals.Store.Customer.Cart.PaymentMethod === 1) { // PAYNAMICS
          this.emptyCart();
          this.globals.clearForm();
          this.globals.Store.isCheckedOut = true;
          console.log('Checkout response [' + this.responseContent + ']');
 
          // this is the old response (res); do not change for now
          this.paymentrequest.nativeElement.value = res; 
        
          // finally, submit the form
          this.formGateway.nativeElement.submit();

        } else if (this.globals.Store.Customer.Cart.PaymentMethod === 3) { // UNIONBANK
          this.emptyCart();
          this.globals.clearForm();
          this.globals.Store.isCheckedOut = true;
          console.log('Checkout response [' + this.responseContent + ']');
          
          var url = this.payURL_ub + this.ub_uuid + '?s=' + await this.ub_encrypt(JSON.parse(complete_order_details), this.resultOrderNumber);
          window.open(url, "_blank");
          this.globals.goToUrl(this.router, 'completed' + '/' + res);

        } else {
          this.emptyCart();
          this.globals.clearForm();
          this.globals.Store.isCheckedOut = true;
          this.globals.goToUrl(this.router, 'completed' + '/' + res);
        }
      },
      (err) => {
        this.isLoading = true;
        this.isRedirecting = false;
        console.error(err);
        this.showErrorMessage('Sorry, your request cannot be process at the moment!');
      },
    );
  }

  getPaypalConfig(): IPayPalConfig {
    return {
      currency: this.globals.Store.Currency,
      clientId: environment.paypalClientId,
      createOrderOnClient: () => {
        return {
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: this.globals.Store.Currency,
                value: this.globals.getGrandTotal().toFixed(2),
              },
              items: [],
            },
          ],
        } as ICreateOrderRequest;
      },
      advanced: {
        commit: 'false',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onInit: (data: IInitCallbackData, actions: IOnInitCallbackActions) => {
        console.log('onInit', data, actions);
        if (!this.IsPageValid(false)) {
          console.log('REJECT');
          actions.disable();
        }
      },
      onApprove: (data, actions) => {
        this.isLoading = true;
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then((details: any) => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        this.isLoading = true;
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data,
        );
        this.confirm();
      },
      onCancel: (data, actions) => {
        this.isLoading = false;
        console.log('OnCancel', data, actions);
      },
      onError: (err) => {
        this.isLoading = false;
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('OnClick', data, actions);
        if (!this.IsPageValid(true)) {
          console.log('REJECT');
          this.isLoading = false;
          actions.reject();
        }
      },
    };
  }

  emptyCart() {
    const cart = this.globals.Store.Customer.Cart;
    cart.Items = [];
    cart.UseSameAddress = true;
    cart.ShippingFee = 0.0;
    cart.Billing.State = '';
    cart.Billing.StateId = '';
    cart.Billing.City = '';
    cart.Billing.CityId = '';
  }

  onChange() {
    // console.log('On Change');
    // this.loadPaypalButton();
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onPaste(event: ClipboardEvent): void {
    // Prevent the default paste behavior
    event.preventDefault();
  }

  IsPageValid(withErrorMessage: boolean): boolean {
    if (!this.captchaResponse) {
      if (withErrorMessage) {
        this.showErrorMessage('Please check the "I\'m not a robot" checkbox.');
      }
      return false;
    }

    if (this.globals.Store.Customer.Cart.DeliveryMethod == 0) {
      if (
        !this.globals.Store.Customer.Cart.Delivery.CityId ||
        !this.globals.Store.Customer.Cart.Delivery.CourierId ||
        !this.globals.Store.Customer.Cart.Delivery.StateId
      ) {
        if (withErrorMessage) {
          this.showErrorMessage('Please fill up the delivery fields above.');
        }
        return false;
      }

      if (!this.validateEmailFormat(this.globals.Store.Customer.Cart.Billing.Email)) {
        if (withErrorMessage) {
          this.showErrorMessage('Please enter your correct email address');
        }
        return false;
      }

      // if (this.validatePhoneNumber(this.globals.Store.Customer.Cart.Billing.PhoneNumber) != null) {
      //   if (withErrorMessage) {
      //     this.showErrorMessage('Please enter your correct mobile number');
      //   }
      //   return false;
      // }
    }

    if (this.globals.Store.Customer.Cart.Items.length === 0) {
      if (withErrorMessage) {
        this.showErrorMessage('Your cart is empty!');
      }
      return false;
    }

    if (
      this.globals.Store.Customer.Cart.Billing.FirstName === '' ||
      this.globals.Store.Customer.Cart.Billing.LastName === '' ||
      this.globals.Store.Customer.Cart.Billing.Email === '' ||
      this.globals.Store.Customer.Cart.Billing.PhoneNumber === '' ||
      this.globals.Store.Customer.Cart.Billing.PhoneNumber.length < 9 ||
      this.globals.Store.Customer.Cart.Billing.Address1 === '' ||
      this.globals.Store.Customer.Cart.Billing.Address2 === ''
    ) {
      if (withErrorMessage) {
        this.showErrorMessage('Please fill up all required fields!');
      }
      return false;
    }
    if (!this.validateEmailFormat(this.globals.Store.Customer.Cart.Billing.Email)) {
      if (withErrorMessage) {
        this.showErrorMessage('Please enter your correct email address');
      }
      return false;
    }
    // if (this.validatePhoneNumber(this.globals.Store.Customer.Cart.Billing.PhoneNumber) != null) {
    //   if (withErrorMessage) {
    //     this.showErrorMessage('Please enter your correct mobile number');
    //   }
    //   return false;
    // }
    if (this.globals.Store.Customer.Cart.DeliveryMethod === 1 && !this.globals.Store.Customer.Cart.UseSameAddress) {
      if (
        this.globals.Store.Customer.Cart.Shipping.FirstName === '' ||
        this.globals.Store.Customer.Cart.Shipping.LastName === '' ||
        this.globals.Store.Customer.Cart.Shipping.Email === '' ||
        this.globals.Store.Customer.Cart.Shipping.PhoneNumber === '' ||
        this.globals.Store.Customer.Cart.Billing.PhoneNumber.length < 9 ||
        this.globals.Store.Customer.Cart.Shipping.Country === '' ||
        this.globals.Store.Customer.Cart.Shipping.State === '' ||
        this.globals.Store.Customer.Cart.Shipping.City === '' ||
        this.globals.Store.Customer.Cart.Shipping.Address1 === '' ||
        this.globals.Store.Customer.Cart.Shipping.Address2 === ''
      ) {
        if (withErrorMessage) {
          this.showErrorMessage('Please fill up all required fields!');
        }
        return false;
      }
      if (!this.validateEmailFormat(this.globals.Store.Customer.Cart.Shipping.Email)) {
        if (withErrorMessage) {
          this.showErrorMessage('Please enter your correct email address');
        }
        return false;
      }
      // if (this.validatePhoneNumber(this.globals.Store.Customer.Cart.Billing.PhoneNumber) != null) {
      //   if (withErrorMessage) {
      //     this.showErrorMessage('Please enter your correct mobile number');
      //   }
      //   return false;
      // }
    }
    if (this.globals.Store.Customer.Cart.DeliveryMethod === 2) {
      if (!this.globals.Store.Customer.Cart.PickupLocation || this.globals.Store.Customer.Cart.PickupLocation <= 0) {
        if (withErrorMessage) {
          this.showErrorMessage('Please choose a pickup location');
        }
        return false;
      }
    } else {
      if (this.globals.Store.Customer.Cart.ShippingFee <= 0.0) {
        if (withErrorMessage) {
          this.showErrorMessage('Invalid shipping fee.');
        }
        return false;
      }
    }
    // OK we are good to go!
    return true;
  }

  validateEmailFormat(email: string): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  showErrorMessage(message: string): void {
    this.snackBar.open(message, 'Error', {
      duration: 2000,
    });
  }

  // validatePhoneNumber(control: any) {
  //   const mobileNumber = control.value;
  //   //var check_format = /^09\d{8}$/.test(mobile);
  //   //if (mobileNumber && mobileNumber.startsWith('09') ) {
  //   if (mobileNumber && /^09\d{8}$/.test(mobileNumber)) {
  //     return null; // Valid mobile number
  //   } else {
  //     return { invalidMobileNumber: true }; // Invalid mobile number
  //   }
  // }




  

  async gcm_encrypt(payload: any) {
    return new Promise((resolve, reject) => {
      var iv = crypto.getRandomValues(new Uint8Array(16));
      var secretKeyHex = environment.ub_aes_key; //to be given by the onboarding team
      var secretKey = this.hexStringToUint8Array(secretKeyHex);

      window.crypto.subtle.importKey('raw', secretKey, 'AES-GCM', true, ['encrypt', 'decrypt'])
      //.then(function (key) {
      .then((key) => {

        var jsonPayLoad = payload[0]; //payload: remove the first [] bracket

        window.crypto.subtle.encrypt({name: 'AES-GCM', iv: iv}, key, new TextEncoder().encode(JSON.stringify(jsonPayLoad)))
        //.then(function (cipherText) {
        .then((cipherText) => {
          var encryptedString = this.arrayBufferToBase64(cipherText);
          var concatenatedArray = this.concatBuffers(iv, cipherText);
          var output = this.arrayBufferToBase64(concatenatedArray);
          var encoded_url = encodeURIComponent(output);

          resolve(encoded_url);

        }).catch((error) => {

          reject(error); // Reject the promise with any encryption errors
          
        });

      });
    });
  }
    


  hexStringToUint8Array(hexString: any) {
    if (hexString.length % 2 != 0)
        throw 'Invalid hexString';
        var arrayBuffer = new Uint8Array(hexString.length / 2);
        for (var i = 0; i < hexString.length; i += 2) {
            var byteValue = parseInt(hexString.substr(i, 2), 16);
            if (Number.isNaN(byteValue))
                throw 'Invalid hexString';
            arrayBuffer[i / 2] = byteValue;
        }
    return arrayBuffer;
  }

  arrayBufferToBase64(buffer: any) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
  }

  concatBuffers(buffer1: any, buffer2: any) {
      var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
      tmp.set(new Uint8Array(buffer1), 0);
      tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
      return tmp.buffer;
  }


  // returns raw json format
  async ub_encrypt(jsonData: any, oid: any): Promise<any> {
    // format the order to ub json format
    
    let totalPrice = 0;

    if (jsonData.Items && Array.isArray(jsonData.Items)) {
      jsonData.Items.forEach((item: { Product: { Price: number; }; Quantity: number; }) => {
        if (item.Product && typeof item.Product.Price === 'number' && typeof item.Quantity === 'number') {
          totalPrice += item.Product.Price * item.Quantity;
        } else {
          console.error('Invalid item data:', item);
        }
      });
    }

    // redir: environment.baseUrl + '/' + jsonData.VendorId + '/completed/' + oid, 

    var amount = totalPrice + jsonData.ShippingFee;
    var email = jsonData.Billing.Email;
    var mobile = "9" + jsonData.Billing.PhoneNumber;
    var redir = environment.baseUrl;
    var name = jsonData.Billing.FirstName + ' ' + jsonData.Billing.LastName;

    const newJsonData = new Array();
    newJsonData.push(
      {
        "Amt": amount,
        "Email": email,
        "Mobile": parseInt(mobile, 10).toString(),
        "Redir": redir,
        "References": [
          {
            "Id": "1",
            "Name": "Reference No.",
            "Val": oid
          },
          {
            "Id": "2",
            "Name": "Account Number",
            "Val": name
          }
        ]
      }
    );

    var result = await this.gcm_encrypt(newJsonData);

    // console.log("https://ubotpsentry-tst1.outsystemsenterprise.com/UPAY/Whitelabel/725BE83B-98B9-3D2B-8BF4-7E72D46D308E?s=" + result);

    return result;
  }
}

