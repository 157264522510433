<div class="p-5">
  Thank you for your order!  
  <div>
    Your order number is : #{{this.OrderId}}
  </div>
  <br>
  <div style="color:red">
    REMINDER: Kindly check your registered email to confirm your order to proceed our transaction.
  </div>
</div>

<div class="col-md-3 px-5" *ngIf="Payment==='ebank'">
  <a href="{{ ebankUrl }}/?oid={{this.OrderId}}&enc={{ this.EncOID }}" target="_blank" class="mat-focus-indicator w-100 p-0 mat-raised-button mat-button-base">Go to E-Bank</a>
</div>